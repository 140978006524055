<template>
  <div class="h-100">
    <div class="d-flex align-items-center flex-column justify-content-center h-100">
      <div
        id="login-card"
        class="card border-0 shadow"
      >
        <div class="card-body p-5 text-center">
          <router-link
            v-if="totpAvailable"
            class="btn btn-gray-7 text-primary text-left mb-3"
            :to="{name: '2faTOTP', query: $route.query}"
          >
            <div class="card-body">
              <div class="row">
                <div class="col-auto">
                  <i class="fad fa-lock-alt fa-2x mt-1" />
                </div>
                <div class="col pl-0">
                  <h6 class="mb-1">
                    Authenticator-App
                  </h6>
                  <p class="mb-0">
                    Melden Sie sich mit Ihrer Authenticator-App an.
                  </p>
                </div>
              </div>
            </div>
          </router-link>
          <router-link
            v-if="u2fAvailable"
            class="btn btn-gray-7 text-primary text-left mb-3"
            :to="{name: '2faU2F', query: $route.query}"
          >
            <div class="card-body">
              <div class="row">
                <div class="col-auto">
                  <i class="fad fa-key fa-2x mt-1" />
                </div>
                <div class="col pl-0">
                  <h6 class="mb-1">
                    Security-Key
                  </h6>
                  <p class="mb-0">
                    Melden Sie sich mit Ihrem Security-Key an.
                  </p>
                </div>
              </div>
            </div>
          </router-link>
          <router-link
            v-if="smsAvailable"
            class="btn btn-gray-7 text-primary text-left mb-3"
            :to="{name: '2faSMS', query: $route.query}"
          >
            <div class="card-body">
              <div class="row">
                <div class="col-auto">
                  <i class="fad fa-sms fa-2x mt-1" />
                </div>
                <div class="col pl-0">
                  <h6 class="mb-1">
                    SMS
                  </h6>
                  <p class="mb-0">
                    Melden Sie sich mit einem SMS-Token an.
                  </p>
                </div>
              </div>
            </div>
          </router-link>
          <div class="text-center">
            <button
              class="btn btn-secondary text-primary"
              @click="logout()"
            >
              Abmelden
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    twoFactor () {
      return this.$store.getters['auth/user'].user.twoFactor
    },
    client () {
      return this.$store.getters['clients/find']().data[0]
    },
    totpAvailable () {
      const globalAvailable = this.client && this.client.twoFactor && this.client.twoFactor.TOTP && this.client.twoFactor.TOTP.enabled
      const userAvailable = this.twoFactor && this.twoFactor.TOTP && this.twoFactor.TOTP.secret
      return globalAvailable && userAvailable
    },
    u2fAvailable () {
      const globalAvailable = this.client && this.client.twoFactor && this.client.twoFactor.U2F && this.client.twoFactor.U2F.enabled
      const userAvailable = this.twoFactor.U2F && this.twoFactor.U2F.keyHandle && this.twoFactor.U2F.publicKey
      return globalAvailable && userAvailable
    },
    smsAvailable () {
      const globalAvailable = this.client && this.client.twoFactor && this.client.twoFactor.SMS && this.client.twoFactor.SMS.enabled
      const userAvailable = this.twoFactor.SMS && this.twoFactor.SMS.mobileNumber && this.twoFactor.SMS.secret
      return globalAvailable && userAvailable
    }
  },
  methods: {
    async logout () {
      await this.$store.dispatch('auth/logout')
      await this.$router.push({ name: 'Login' })
    }
  }
}
</script>

<style lang="scss" scoped>
#login-card {
  max-width: 500px;
}
</style>
